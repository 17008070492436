<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top pn-legal-banner-container w-full text-white-1 z-25">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 -right-12 mobileL:right-0 h-full object-cover z-5 z-15" src="../../../assets/images/privacy-policy/pn-legal-banner.png" alt="Legal Terms Banner">
                <div class="flex flex-col mx-28 mobileL:mx-0 text-left mobileL:text-center z-10 max-w-xs lg:max-w-lg 2xl:max-w-2xl p-0 mobileL:p-4 
                ml-6 mobileL:ml-20 lg:ml-32 xl:ml-40 2xl:ml-48 relative mt-0 mobileL:-mt-8">
                    <div class="relative inline-block">
                        <span class="pn-legal-bg-highlight absolute top-4 mobileL:top-8 lg:top-10 2xl:top-12 left-0 w-5/6 mobileL:w-full h-1/2 2xl:h-1/2 z-10"></span>
                        <img class="absolute -top-2 -left-3 mobileL:-left-2 2xl:left-6 2xl:top-1 w-4 lg:w-5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                        <div class="text-4xl md:text-5xl lg:text-7xl 2xl:text-8xl font-semibold relative z-20">
                            <h1 class="font-kannada font-semibold">Legal Terms</h1>
                        </div>
                        <img class="absolute hidden mobileL:block top-0 lg:-top-4 -right-16 lg:-right-20 z-0 lg:w-28 2xl:w-auto w-20" src="../../../assets/images/home-page/sketch-line.png" alt="">
                    </div>
                    <p class="2xl:mt-6 text-sm mobileL:text-lg lg:text-2xl 2xl:text-4xl -mx-12 ml-0 mobileL:mx-0 2xl:-mx-12 leading-snug">
                        We know some things are best kept private. 
                        At Lay Bare, we value your concerns about privacy 
                        and we want to make sure you feel comfortable using our 
                        website and application.
                    </p>
                </div>
                <img class="absolute hidden mobileL:block top-8 left-12 lg:top-24 lg:left-24 object-cover z-5 pt-4 w-20 lg:w-28 xl:w-36" src="../../../assets/images/contact-us/pn-leaf.png" alt="leaf">
                <img class="absolute hidden mobileL:block top-4 left-80 lg:top-8 lg:left-100 xl:left-120 object-cover transform z-5 pt-4 w-16 lg:w-28 xl:w-36" src="../../../assets/images/contact-us/pn-leaf-3.png" alt="leaf">
                <img class="absolute hidden mobileL:block bottom-16 left-44 lg:bottom-24 lg:left-40 xl:left-100 2xl:left-120 object-cover z-5 pt-4 w-20 lg:w-28 xl:w-36" src="../../../assets/images/contact-us/pn-leaf-2.png" alt="leaf">
            </div>
        </div>
        <div class="relative w-full legal-scrollable-height pn-legal-bg">
            <div class="relative mx-auto md:text-base md:grid md:grid-cols-6 lg:text-xl md:sticky h-screen">
            <div class="relative col-span-2 overflow-hidden">
                <div class="md:text-lg hidden md:grid legal-scrollable-height md:sticky md:overflow-y-scroll max-h-[47vh]">
                    <div class=" md:mt-0 bg-green-15 z-10 font-semibold text-white-1 font-kannada pl-8 pr-2">
                        <h2 class="mb-4 text-2xl lg:text-3xl pt-12">Table of Contents</h2>
                        <ol class="text-xl lg:text-2xl pl-4 xl:pl-8">
                            <li class="mb-3">
                                <a href="#privacy-notice" class="text-left cursor-pointer hover:text-green-3">Privacy Notice</a>   
                                <ol class="ml-6 xl:ml-8 list-inside space-y-4">
                                    <li class="list-item">
                                        <a href="#personal-data-and-other-information-we-collect" class="text-left cursor-pointer hover:text-green-3">
                                        Personal Data And Other Information We Collect
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#use-and-disclosure-of-personal-data" class="text-left cursor-pointer hover:text-green-3">
                                        Use And Disclosure Of Personal Data
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#international-data-transfers" class="text-left cursor-pointer hover:text-green-3">
                                        International Data Transfers
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#retention-and-disposal-of-your-personal-information" class="text-left cursor-pointer hover:text-green-3">
                                        Retention And Disposal Of Your Personal Information
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#your-rights" class="text-left cursor-pointer hover:text-green-3">
                                        Your Rights
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#protecting-your-information" class="text-left cursor-pointer hover:text-green-3">
                                        Protecting Your Information
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#third-party-websites" class="text-left cursor-pointer hover:text-green-3">
                                        Third Party Websites
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#cookies-and-similar-technologies" class="text-left cursor-pointer hover:text-green-3">
                                        Cookies And Similar Technologies
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#management-and-security" class="text-left cursor-pointer hover:text-green-3">
                                        Management And Security
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#our-data-protection-office" class="text-left cursor-pointer hover:text-green-3">
                                        Our Data Protection Office
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#updates-to-the-policy" class="text-left cursor-pointer hover:text-green-3">
                                        Updates To The Policy
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li class="mb-5">
                                <a href="#privacy-policy" class="text-left cursor-pointer hover:text-green-3">Privacy Policy</a>
                                <ol class="ml-6 xl:ml-8  list-inside space-y-4">
                                    <li class=" list-inside">
                                        <a href="#why-we-process-your-data" class="text-left cursor-pointer hover:text-green-3">
                                        Why we process your data
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#limitation" class="text-left cursor-pointer hover:text-green-3">
                                        Limitation
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#integrity-and-confidentiality" class="text-left cursor-pointer hover:text-green-3">
                                        Integrity and Confidentiality
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <a href="#confidentiality-commitment" class="text-left cursor-pointer hover:text-green-3">Confidentiality Commitment</a>
                                <ol class="ml-6 xl:ml-8  list-inside space-y-4">
                                    <li class="list-item">
                                        <a href="#confidentiality-obligation" class="text-left cursor-pointer hover:text-green-3">
                                        Confidentiality Obligation
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#non-disclosure" class="text-left cursor-pointer hover:text-green-3">
                                        Non-Disclosure And Non-Use
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#safeguarding-measures" class="text-left cursor-pointer hover:text-green-3">
                                        Safeguarding Measures
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#third-party-disclosure" class="text-left cursor-pointer hover:text-green-3">
                                        Third-Party Disclosure
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#limitations" class="text-left cursor-pointer hover:text-green-3">
                                        Limitations
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#integrity-confidentiality" class="text-left cursor-pointer hover:text-green-3">
                                        Integrity & Confidentiality
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#account-deletion" class="text-left cursor-pointer hover:text-green-3">
                                        Request for Account Deletion
                                        </a>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="mobileL:col-span-4 w-full legal-scrollable-height overflow-y-auto">
                <div class="md:text-xl block mobileL:hidden sticky -top-0.5">
                    <div class="md:mt-0 mb-4 bg-green-15 text-white-1 font-kannada font-semibold">
                        <h2 class="mb-4 pt-3 pb-1 text-lg pl-4 md:text-3xl cursor-pointer sticky" @click="toggleAccordion">Table of Content
                            <span :class="{ 'transform rotate-90': isAccordionOpen, 'transform rotate-0': !isAccordionOpen }" class="ml-2 inline-block">&#9654;</span>
                        </h2>
                        <ol v-show="isAccordionOpen" class="h-100 pl-4 pb-2 overflow-y-auto">
                            <li class="mb-5">
                                <a href="#privacy-notice" class="text-left cursor-pointer hover:text-green-3">Privacy Notice</a>
                                <ol class="space-y-1 ml-4">
                                    <li class="list-item">
                                        <a href="#personal-data-and-other-information-we-collect" class="text-left cursor-pointer hover:text-green-3">
                                        Personal Data And Other Information We Collect
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#use-and-disclosure-of-personal-data" class="text-left cursor-pointer hover:text-green-3">
                                        Use And Disclosure Of Personal Data
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#international-data-transfers" class="text-left cursor-pointer hover:text-green-3">
                                        International Data Transfers
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#retention-and-disposal-of-your-personal-information" class="text-left cursor-pointer hover:text-green-3">
                                        Retention And Disposal Of Your Personal Information
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#your-rights" class="text-left cursor-pointer hover:text-green-3">
                                        Your Rights
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#protecting-your-information" class="text-left cursor-pointer hover:text-green-3">
                                        Protecting Your Information
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#third-party-websites" class="text-left cursor-pointer hover:text-green-3">
                                        Third Party Websites
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#cookies-and-similar-technologies" class="text-left cursor-pointer hover:text-green-3">
                                        Cookies And Similar Technologies
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#management-and-security" class="text-left cursor-pointer hover:text-green-3">
                                        Management And Securiry
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#our-data-protection-office" class="text-left cursor-pointer hover:text-green-3">
                                        Our Data Protection Office
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#updates-to-the-policy" class="text-left cursor-pointer hover:text-green-3">
                                        Updates To The Policy
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li class="mb-3">
                                <a href="#privacy-policy" class="text-left cursor-pointer hover:text-green-3">Privacy Policy</a>
                                <ol class="ml-4 space-y-1">
                                    <li class="list-item">
                                        <a href="#why-we-process-your-data" class="text-left cursor-pointer hover:text-green-3">
                                        Why we process your data
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#limitation" class="text-left cursor-pointer hover:text-green-3">
                                        Limitation
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#integrity-and-confidentiality" class="text-left cursor-pointer hover:text-green-3">
                                        Integrity and Confidentiality
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <a href="#confidentiality-commitment" class="text-left cursor-pointer hover:text-green-3">
                                    Confidentiality Commitment
                                </a>
                                <ol class="ml-4 space-y-1">
                                    <li class="list-item">
                                        <a href="#confidentiality-obligation" class="text-left cursor-pointer hover:text-green-3">
                                        Confidentiality Obligation
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#non-disclosure" class="text-left cursor-pointer hover:text-green-3">
                                        Non-Disclosure And Non-Use
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#safeguarding-measures" class="text-left cursor-pointer hover:text-green-3">
                                        Safeguarding Measures
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#third-party-disclosure" class="text-left cursor-pointer hover:text-green-3">
                                        Third-Party Disclosure
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#limitations" class="text-left cursor-pointer hover:text-green-3">
                                        Limitations
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#integrity-confidentiality" class="text-left cursor-pointer hover:text-green-3">
                                        Integrity & Confidentiality
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#account-deletion" class="text-left cursor-pointer hover:text-green-3">
                                        Request for Account Deletion
                                        </a>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="w-full">
                    <div class="flex flex-col overflow-x-hidden">
                        <PrivacyNotice />
                        <div class="mobileL:border-t-2"></div>
                        <PrivacyPolicy/>
                        <div class="mobileL:border-t-2"></div>
                        <ConfidentialityCommitment />
                    </div>
                </div>
            </div>
        </div>
      </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/PassionailsFooter.vue'
import PrivacyNotice from '../../../components/legal-terms/PNPrivacyNotice.vue'
import PrivacyPolicy from '../../../components/legal-terms/PNPrivacyPolicy.vue'
import ConfidentialityCommitment from '../../../components/legal-terms/PNConfidentialityCommitment.vue'

export default {
    components: {
        Header,
        Footer,
        PrivacyNotice,
        PrivacyPolicy,
        ConfidentialityCommitment
    },
    mounted() {
        this.$gtag.pageview('/passionails/legal-terms')
    },
    data() {
        return {
            isAccordionOpen: false
            };
    },
    methods:{
        scrollToTop() {
            document.body.scrollTo({ top: 0 })
        },
        toggleAccordion() {
            this.isAccordionOpen = !this.isAccordionOpen;
        }
    }
}
</script>

<style>
.pn-legal-banner-container {
    height: 700px;
    background-size: cover;
    background: #DC9C9D;
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .pn-legal-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .pn-legal-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .pn-legal-banner-container{
        height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.pn-legal-banner-title {
    color: #FFF;
    font-style: normal;
    font-weight: 600;
}

.legal-scrollable-height {
  height: 83vh;
}

@media (min-width: 1500px) and (max-height: 1500px) {
    .legal-scrollable-height {
        height: 85;
    }
}

.pn-legal-bg-highlight {
    position: absolute;
    background: #7D4979;
    left: 0px;
}

.pn-legal-bg {
    background: linear-gradient(180deg, #AB7391 33.88%, rgba(171, 123, 163, 0.00) 279.62%);
}

::-webkit-scrollbar {
  width: 0; 
}

</style>